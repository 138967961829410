<template>
    <v-card flat>
        <!-- SEARCH TEXT CARD -->
        <v-card v-if="true" flat style="width:500px;margin:auto;margin-top:10px" color="white">
            <!--            <v-img
                            src="http://valesco.es/sites/default/files/logo_web_4.png"
                            height="200px"
                            ></v-img>-->

            <v-container style="height:160px;overflow: hidden" class="pa-0">
                <v-row class="pa-0 ma-0" justify="center">
                    <v-col cols="10" align-self="center">
                        <v-text-field v-if="debugTextSearch" id="regexInput" class="mb-2" v-model="search_regex"
                            label="Regex expression" @keydown.enter="doTextSearch()" height="35" solo rounded
                            hide-details="true" value="">
                        </v-text-field>
                        <v-text-field class="mb-2" v-model="search_words" label="Buscar palabras"
                            @keydown.enter="doTextSearch()"
                            hint="Escriba las palabras que desea buscar separadas por espacios. Separe las palabras con (|) para indicar opcionalidad. Utilice (?) para indicar que la palabra buscada puede tener cualquier carácter de palabra en esa posición. Utilice (*) para obtener cualquier sucesión de caracteres de palabra."
                            height="35" solo rounded>
                            <template v-slot:prepend-inner>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="doTextSearch()">search</v-icon>
                                    </template>
                                    "Buscar estas palabras en las conversaciones"
                                </v-tooltip>
                            </template>
                            <template v-slot:append>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="search_words = ''">close</v-icon>
                                    </template>
                                    "Borrar texto"
                                </v-tooltip>
                            </template>

                            <v-icon slot="append" @click="search_words = ''">
                                close
                            </v-icon>
                        </v-text-field>
                    </v-col>
                    <!-- <v-col class="" align-self="start" cols="2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mb-6" v-on="on" @click.stop="filters_dialog = true">
                                    <v-icon color="black">mdi-filter</v-icon>
                                </v-btn>
                            </template>
                            "Definir filtros de búsqueda"
                        </v-tooltip>
                    </v-col> -->
                </v-row>
            </v-container>
        </v-card>
        <!-- {{ search_index }} -->
        <!-- {{ defined_searchs[search_index]['filter_form'] }} -->
        <!-- SEARCH RESULTS SECTION -->
        <v-container>
            <v-row justify="start">
                <!-- MAIN SEARCH SECTION -->
                <v-col :cols="result_card_cols" class="pa-3">
                    <v-alert v-if="defined_searchs[0]['error'] && defined_searchs[0]['error'].length > 0" border="left"
                        colored-border type="error" elevation="2">
                        {{ defined_searchs[0]['error'] }}
                    </v-alert>
                    <v-card v-if="this.defined_searchs" class="filter-row-color my-3 search-value-col" outlined>
                        <v-row no-gutters>
                            <v-col class="pa-1" cols="auto">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="search_index = 0; filters_dialog = true">mdi-tune</v-icon>
                                    </template>
                                    "Configurar búsqueda principal"
                                </v-tooltip>
                            </v-col>
                            <v-col class="search-label-col" cols="auto">
                                Buscar entre los años
                            </v-col>
                            <v-col class="search-value-col" cols="2">
                                <v-text-field v-model="defined_searchs[0]['filter_form']['conv_year_init']" outlined
                                    class="defined_searchs[search_index]['filter_form']-input" hide-details="true" dense
                                    solo @keydown.enter="doSearch(0)">
                                </v-text-field>
                            </v-col>
                            <v-col class="search-label-col" cols="auto">
                                y
                            </v-col>
                            <v-col class="search-value-col" cols="2">
                                <v-text-field v-model="defined_searchs[0]['filter_form']['conv_year_end']" outlined
                                    hide-details="true" solo dense @keydown.enter="doSearch(0)">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card id="resultsCard" class="item-card">
                        <v-card outlined class="filter-row-color my-3 pa-1">
                            <v-row no-gutters justify="start">
                                <v-col v-if="!this.defined_searchs[0]['loading_results']" class="pa-1 pt-2" cols="auto">
                                    <!--Encontrados {{result_items?result_items.length:0}} resultados en {{result_conversation_count}} conversaciones al buscar por <span style="color: red">"{{search_text}}"</span>-->
                                    Encontrados {{ defined_searchs[0]["result_tokens_count"] }} ejemplos en {{
                                        this.defined_searchs[0]['result_items']
                                        ? this.defined_searchs[0]['result_items'].length
                                        : 0 }}
                                    intervenciones en {{ this.defined_searchs[0]['result_conversation_count'] }}
                                    conversaciones al buscar por <span style="color: darkorchid">"{{ search_text }}"</span>
                                </v-col>
                                <v-col v-if="this.defined_searchs[0]['loading_results']" class="pa-1 pt-2" cols="auto">
                                    Buscando en el corpus...
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col v-if="defined_searchs[0]['selected_rows'].length > 0" class="pa-1" cols="auto">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                @click="generateResultDoc(defined_searchs[0]['selected_rows'], 0)">mdi-file-word</v-icon>
                                        </template>
                                        "Descargar resultados seleccionados en word"
                                    </v-tooltip>
                                </v-col>
                                <v-col v-if="defined_searchs[0]['selected_rows'].length > 0" class="pa-1" cols="auto">

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                @click="generateResultExcel(defined_searchs[0]['selected_rows'], 0)">mdi-file-excel</v-icon>
                                        </template>
                                        "Descargar resultados seleccionados en excel"
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- RESULT DATA TABLE -->
                        <v-data-table class="transparent elevation-2" item-key="cod"
                            v-model="defined_searchs[0]['selected_rows']" :headers="defined_searchs[0]['result_headers']"
                            :items="defined_searchs[0]['result_items']" :items-per-page="20" locale="es-ES"
                            :expanded.sync="expanded" single-expand @item-expanded="loadInterventionDetails" show-select
                            :loading="this.defined_searchs[0]['loading_results']" loading-text="Cargando datos..."
                            no-data-text="No se han encontrado resultados" dense
                            :options.sync="defined_searchs[0]['pagination']" :footer-props="{
                                itemsPerPageOptions: [10, 20, 30],
                                'items-per-page-text': 'Intervenciones por página'
                            }">

                            <template v-slot:item="{ item, index, expand, isExpanded }">
                                <tr :id="'row_' + item['cod']"
                                    :class="item['is_observation'] ? 'observation' : item.is_direct_speech ? 'directSpeech' : ''"
                                    :key="item.cod">
                                    <!--@click="checkSelected(item)"-->
                                    <td class="text-start body-1" style="width:1px;min-width:1px;padding: 0 12px;">
                                        <v-checkbox v-model="defined_searchs[0]['selected_rows']" :value="item"
                                            style="margin:0px;padding:0px" hide-details
                                            @change="checkSelected(item)"></v-checkbox>
                                    </td>
                                    <td class="text-xs-left">
                                        <v-btn small text icon @click="expand(!isExpanded)">
                                            <v-icon color="primary" v-if="isExpanded">mdi-chevron-down</v-icon>
                                            <v-icon color="orange" v-else>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-center body-1">
                                        {{ item["conversation"]["name"] }}
                                    </td>
                                    <td class="text-center body-1">
                                        {{ item["intervention_type"] }}
                                    </td>
                                    <td style="font-family: monospace !important;" class="text-center body-1"
                                        v-html="item.conversation_speaker">
                                    </td>
                                    <!--                                <td class="text-left body-1">
                                                                    {{item["cod"]}}
                                                                </td>-->
                                    <td class="text-left body-1">
                                        <span v-if="debugTextSearch">*{{ item["cod"] }}*</span>
                                        <div class="interv-contents" v-html="highlight(search_index, item)">
                                        </div>
                                        <span v-if="debugTextSearch">*{{ item["search_data"] ?
                                            item["search_data"]["tokens_parsed"].toString() : "" }}*</span>
                                    </td>
                                    <!-- <td class="text-xs-left">
                                        <v-btn small text icon @click="loadContextPanel(item)">
                                            <v-icon color="primary" v-if="hasContextPanel(item)">mdi-eye-settings</v-icon>
                                            <v-icon color="gray" v-else>mdi-eye-settings</v-icon>
                                        </v-btn>
                                    </td> -->
                                </tr>
                            </template>

                            <!-- INTERVENTION AUDIO SECTION -->
                            <template v-slot:expanded-item="{ headers, item }">
                                <td style="padding:0" :colspan="headers.length">
                                    <v-card v-if="item" width="100%" height="60">
                                        <!--More info about {{ item.cod }}--asdfasd-->
                                        <v-container justify-center grid-list-xl fluid>
                                            <v-layout row wrap>
                                                <v-flex class="row-padding" xs12>
                                                    <v-row no-gutters justify="center">
                                                        <v-col cols="auto">
                                                            <v-row no-gutters justify="center">
                                                                <loading-wheel v-if="audio_is_loading"></loading-wheel>
                                                                <div :id="'plyr_inter_' + item['cod']">
                                                                    <audio id="audio_player">
                                                                    </audio>
                                                                </div>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-card>
                                </td>
                            </template>

                            <template v-slot:[`footer.page-text`]="items"> {{ items.pageStart }} - {{ items.pageStop }} de
                                {{ items.itemsLength }}
                            </template>
                        </v-data-table>

                        <!-- {{ JSON.stringify(defined_searchs[0]['filter_form']) }} -->
                    </v-card>
                </v-col>

                <!-- COMPARE SEARCH SECTION -->
                <v-col :cols="detail_card_cols" class="pa-3">
                    <v-alert v-if="defined_searchs[1]['error'] && defined_searchs[1]['error'].length > 0" border="left"
                        colored-border type="error" elevation="2">
                        {{ defined_searchs[1]['error'] }}
                    </v-alert>
                    <v-card id="resultsCard" class="item-card">
                        <v-card v-if="this.defined_searchs" class="filter-row-color my-3 search-value-col" outlined>
                            <v-row no-gutters>
                                <v-col class="pa-1" cols="auto">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                @click="search_index = 1; filters_dialog = true">mdi-tune</v-icon>
                                        </template>
                                        "Configurar búsqueda principal"
                                    </v-tooltip>
                                </v-col>
                                <v-col class="search-label-col" cols="auto">
                                    Buscar entre los años
                                </v-col>
                                <v-col class="search-value-col" cols="2">
                                    <v-text-field v-model="defined_searchs[1]['filter_form']['conv_year_init']" outlined
                                        class="defined_searchs[search_index]['filter_form']-input" hide-details="true" dense
                                        solo @keydown.enter="doSearch(1)">
                                    </v-text-field>
                                </v-col>
                                <v-col class="search-label-col" cols="auto">
                                    y
                                </v-col>
                                <v-col class="search-value-col" cols="2">
                                    <v-text-field v-model="defined_searchs[1]['filter_form']['conv_year_end']" outlined
                                        hide-details="true" solo dense @keydown.enter="doSearch(1)">2020
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card outlined class="filter-row-color my-3 pa-1">
                            <v-row no-gutters justify="start">
                                <v-col v-if="!this.defined_searchs[1]['loading_results']" class="pa-1 pt-2" cols="auto">
                                    <!--Encontrados {{result_items?result_items.length:0}} resultados en {{result_conversation_count}} conversaciones al buscar por <span style="color: red">"{{search_text}}"</span>-->
                                    Encontrados {{ defined_searchs[1]["result_tokens_count"] }} ejemplos en {{
                                        this.defined_searchs[1]['result_items']
                                        ? this.defined_searchs[1]['result_items'].length
                                        : 0 }}
                                    intervenciones en {{ this.defined_searchs[1]['result_conversation_count'] }}
                                    conversaciones al buscar por <span style="color: darkorchid">"{{ search_text }}"</span>
                                </v-col>
                                <v-col v-if="this.defined_searchs[1]['loading_results']" class="pa-1 pt-2" cols="auto">
                                    Buscando en el corpus...
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col v-if="defined_searchs[1]['selected_rows'].length > 0" class="pa-1" cols="auto">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                @click="generateResultDoc(defined_searchs[1]['selected_rows'], 1)">mdi-file-word</v-icon>
                                        </template>
                                        "Descargar resultados seleccionados en word"
                                    </v-tooltip>
                                </v-col>
                                <v-col v-if="defined_searchs[1]['selected_rows'].length > 0" class="pa-1" cols="auto">

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                @click="generateResultExcel(defined_searchs[1]['selected_rows'], 1)">mdi-file-excel</v-icon>
                                        </template>
                                        "Descargar resultados seleccionados en excel"
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- RESULT DATA TABLE -->
                        <v-data-table class="transparent elevation-2" item-key="cod"
                            v-model="defined_searchs[1]['selected_rows']" :headers="defined_searchs[1]['result_headers']"
                            :items="defined_searchs[1]['result_items']" :items-per-page="20" locale="es-ES"
                            :expanded.sync="expanded" single-expand @item-expanded="loadInterventionDetails" show-select
                            :loading="this.defined_searchs[1]['loading_results']" loading-text="Cargando datos..."
                            no-data-text="No se han encontrado resultados" dense
                            :options.sync="defined_searchs[1]['pagination']" :footer-props="{
                                itemsPerPageOptions: [10, 20, 30],
                                'items-per-page-text': 'Intervenciones por página'
                            }">

                            <template v-slot:item="{ item, index, expand, isExpanded }">
                                <tr :id="'row_' + item['cod']"
                                    :class="item['is_observation'] ? 'observation' : item.is_direct_speech ? 'directSpeech' : ''"
                                    :key="item.cod">
                                    <!--@click="checkSelected(item)"-->
                                    <td class="text-start body-1" style="width:1px;min-width:1px;padding: 0 12px;">
                                        <v-checkbox v-model="defined_searchs[1]['selected_rows']" :value="item"
                                            style="margin:0px;padding:0px" hide-details
                                            @change="checkSelected(item)"></v-checkbox>
                                    </td>
                                    <td class="text-xs-left">
                                        <v-btn small text icon @click="expand(!isExpanded)">
                                            <v-icon color="primary" v-if="isExpanded">mdi-chevron-down</v-icon>
                                            <v-icon color="orange" v-else>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-center body-1">
                                        {{ item["conversation"]["name"] }}
                                    </td>
                                    <td class="text-center body-1">
                                        {{ item["intervention_type"] }}
                                    </td>
                                    <td style="font-family: monospace !important;" class="text-center body-1"
                                        v-html="item.conversation_speaker">
                                    </td>
                                    <!--                                <td class="text-left body-1">
                                                                    {{item["cod"]}}
                                                                </td>-->
                                    <td class="text-left body-1">
                                        <span v-if="debugTextSearch">*{{ item["cod"] }}*</span>
                                        <div class="interv-contents" v-html="highlight(search_index, item)">
                                        </div>
                                        <span v-if="debugTextSearch">*{{ item["search_data"] ?
                                            item["search_data"]["tokens_parsed"].toString() : "" }}*</span>
                                    </td>
                                    <!-- <td class="text-xs-left">
                                        <v-btn small text icon @click="loadContextPanel(item)">
                                            <v-icon color="primary" v-if="hasContextPanel(item)">mdi-eye-settings</v-icon>
                                            <v-icon color="gray" v-else>mdi-eye-settings</v-icon>
                                        </v-btn>
                                    </td> -->
                                </tr>
                            </template>

                            <!-- INTERVENTION AUDIO SECTION -->
                            <template v-slot:expanded-item="{ headers, item }">
                                <td style="padding:0" :colspan="headers.length">
                                    <v-card v-if="item" width="100%" height="60">
                                        <!--More info about {{ item.cod }}--asdfasd-->
                                        <v-container justify-center grid-list-xl fluid>
                                            <v-layout row wrap>
                                                <v-flex class="row-padding" xs12>
                                                    <v-row no-gutters justify="center">
                                                        <v-col cols="auto">
                                                            <v-row no-gutters justify="center">
                                                                <loading-wheel v-if="audio_is_loading"></loading-wheel>
                                                                <div :id="'plyr_inter_' + item['cod']">
                                                                    <audio id="audio_player">
                                                                    </audio>
                                                                </div>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-card>
                                </td>
                            </template>

                            <template v-slot:[`footer.page-text`]="items"> {{ items.pageStart }} - {{ items.pageStop }} de
                                {{ items.itemsLength }} </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <!-- <p v-if="defined_searchs">
            {{ defined_searchs[search_index]['filter_form']['options']['token_mode'] }}
        </p> -->
        <!-- <v-dialog v-model="filters_dialog" v-if="false" max-width="650"> -->
        <v-dialog v-model="filters_dialog" max-width="650">
            <!-- FILTERS SECTION -->
            <v-container class="pa-0" fluid>
                <!-- FILTER TABS -->
                <v-row no-gutters v-if="true" justify="center">
                    <v-col cols="12" class="pa-0">
                        <v-card class="pa-3 filter-color" style="background-color: transparent;">
                            <!-- {{ defined_searchs[search_index]['filter_form']['options'] }}{{ search_index }} -->
                            <v-card-title class="filter-color py-0" style="min-height:10px" hide-actions dense>
                                <h3 class="filter-color font-weight-bold text-center" style="line-height:10px">
                                    Opciones de búsqueda
                                </h3>
                            </v-card-title>

                            <v-card-text class="filter-color pa-0 ma-0" style="padding:0px !important;font-size:90%">
                                <v-toolbar class="filter-color" dense flat>
                                    <v-tabs v-model="selected_entity_tab" height="35">
                                        <v-tab v-for="searchFilter in search_filters" :key="searchFilter['id']"
                                            :href="'#tab-' + searchFilter['id']" small class="px-2" dense>
                                            <v-icon dense left>{{ searchFilter.icon }}</v-icon>
                                            {{ searchFilter.name }}
                                        </v-tab>
                                    </v-tabs>
                                </v-toolbar>

                                <v-tabs-items v-model="selected_entity_tab">
                                    <v-tab-item v-for="searchFilter in search_filters" :key="searchFilter['id']"
                                        :value="'tab-' + searchFilter['id']">

                                        <!-- OPTIONS SECTION -->
                                        <v-container v-if="searchFilter['id'] === 'options'" class="filter-color pa-0"
                                            fluid>
                                            <v-card class="filter-row-color my-0 mb-2 pa-1" outlined>
                                                <v-row no-gutters>
                                                    <v-col class="search-label-col" cols="auto">
                                                        Buscar por
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="auto">
                                                        <v-btn-toggle
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['token_mode']"
                                                            dense mandatory>
                                                            <v-btn color="white" :value="'shape'" small>
                                                                Forma
                                                            </v-btn>
                                                            <v-btn color="white" :value="'lemma'" small>
                                                                Lema
                                                            </v-btn>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                    <v-col class="search-label-col" cols="auto">
                                                        considerar
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="auto">
                                                        <v-btn-toggle
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['capital_mode']"
                                                            dense>
                                                            <v-btn color="white" :value="'uppercase'" small>
                                                                Mayúsculas
                                                            </v-btn>
                                                            <!--                                                            <v-btn
                                                                                                                                            color="white"  
                                                                                                                                            :value="'lowercase'"
                                                                                                                                            small>
                                                                                                                                            Minúsculas
                                                                                                                                        </v-btn>-->
                                                        </v-btn-toggle>
                                                    </v-col>
                                                    <v-col class="search-label-col" cols="auto">
                                                        /
                                                    </v-col>
                                                    <v-col class="search-value-col px-0" cols="auto">
                                                        <v-btn-toggle
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['accents']"
                                                            dense>
                                                            <v-btn color="white" :value="'accents'" small>
                                                                Acentos
                                                            </v-btn>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card class="filter-row-color mb-3 search-value-col" outlined>
                                                <v-row no-gutters>
                                                    <v-col class="search-label-col" cols="auto">
                                                        Aparece
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="auto">
                                                        <v-btn-toggle
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['filter_position']"
                                                            dense>
                                                            <v-btn color="white" :value="'preceded'" small>
                                                                <!--<v-icon>mdi-format-align-center</v-icon>-->
                                                                Precedido
                                                            </v-btn>
                                                            <v-btn color="white" :value="'followed'" small>
                                                                <!--<v-icon>mdi-format-align-left</v-icon>-->
                                                                Seguido
                                                            </v-btn>
                                                            <v-btn color="white" :value="'both'" small>
                                                                <!--<v-icon>mdi-format-align-left</v-icon>-->
                                                                ambos
                                                            </v-btn>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                    <v-col class="search-label-col" cols="auto">
                                                        por
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="auto">
                                                        <v-btn-toggle
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['filter_position_char']"
                                                            dense>
                                                            <v-btn color="white" :value="'exclamation_mark'" small>
                                                                !¡
                                                            </v-btn>
                                                            <v-btn color="white" :value="'question_marks'" small>
                                                                ¿?
                                                            </v-btn>
                                                            <v-btn color="white" :value="'slash'" small>
                                                                /
                                                            </v-btn>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card v-if="false" class="filter-row-color mb-3 search-value-col" outlined>
                                                <v-row no-gutters>
                                                    <v-col cols="12">
                                                        Clase de palabra
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="auto">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['word_type']"
                                                            :items="word_types" dense solo outlined hide-details="true"
                                                            @change="resetWordTypeSection(search_index)"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isPronomTypeVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Tipo
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete v-model="c" :items="pronom_types" dense solo
                                                            outlined hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isDetTypeVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Tipo
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['type']"
                                                            :items="det_types" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isSustTypeVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Tipo
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['type']"
                                                            :items="sust_types" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isPronomSubtypeVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Subtipo
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['word_type']"
                                                            :items="pronom_subtypes" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isPersonVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Persona
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['person']"
                                                            :items="persons" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isGenreVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Género
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['genre']"
                                                            :value="defined_searchs[search_index]['filter_form']['options']['genre']"
                                                            :items="genres" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isNumberVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Número
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['number']"
                                                            :items="numbers" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isMoodVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Modo
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['mood']"
                                                            :items="moods" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="isTenseVisible(search_index)" no-gutters>
                                                    <v-col cols="12">
                                                        Tiempo
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['options']['tense']"
                                                            :items="tenses" dense solo outlined
                                                            hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-container>

                                        <!-- METADATA SECTION -->
                                        <v-container v-if="searchFilter['id'] === 'metadata'" class="filter-color pa-0"
                                            fluid>
                                            <v-card class="filter-row-color my-3 search-value-col" outlined>
                                                <v-row no-gutters>
                                                    <!--{{conversations}}-->
                                                    <v-col class="search-label-col" cols="auto">
                                                        Buscar en conversación
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['metadata']['conversation']"
                                                            :items="conversations" item-text="name" item-value="cod" dense
                                                            solo outlined hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <!--{{conversations}}-->
                                                    <v-col class="search-label-col" cols="auto">
                                                        Tipo de conversación
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-autocomplete
                                                            v-model="defined_searchs[search_index]['filter_form']['metadata']['conversation_type']"
                                                            :items="conversation_types" item-text="name" item-value="cod"
                                                            dense solo outlined hide-details="true"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card class="filter-row-color my-3 search-value-col" outlined>
                                                <v-row no-gutters justify="start">
                                                    <v-col class="search-label-col" cols="12">
                                                        Buscar hablante
                                                    </v-col>
                                                    <v-col class="search-label-col" cols="auto">
                                                        Género
                                                    </v-col>
                                                    <v-col cols="2" class="search-value-col mr-2">
                                                        <v-radio-group class="mt-0"
                                                            v-model="defined_searchs[search_index]['filter_form']['metadata']['speaker_genre']"
                                                            hide-details="true" color="purple" row dense>
                                                            <v-radio style="" dense label="Hombre" value="H">
                                                            </v-radio>
                                                            <v-radio label="Mujer" value="M">
                                                            </v-radio>
                                                        </v-radio-group>
                                                    </v-col>
                                                    <v-col class="search-label-col" cols="auto">
                                                        Nivel educativo
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-combobox
                                                            v-model="defined_searchs[search_index]['filter_form']['metadata']['speaker_educational_level']"
                                                            :items="educational_levels" item-value="cod" item-text="name"
                                                            outlined dense hide-details="true" solo>
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-spacer></v-spacer>
                                                </v-row>
                                                <v-row no-gutters justify="start">
                                                    <v-col class="search-label-col" cols="auto">
                                                        Edad
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-combobox class="mr-3"
                                                            v-model="defined_searchs[search_index]['filter_form']['metadata']['speaker_age_range']"
                                                            :items="age_ranges" item-value="cod" item-text="name" outlined
                                                            dense hide-details="true" solo>
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-col class="search-label-col" cols="auto">
                                                        Edad 2
                                                    </v-col>
                                                    <v-col class="search-value-col" cols="3">
                                                        <v-combobox
                                                            v-model="defined_searchs[search_index]['filter_form']['metadata']['speaker_age_range_2']"
                                                            :items="age_ranges_2" item-value="cod" item-text="name" outlined
                                                            dense hide-details="true" solo>
                                                        </v-combobox>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-container>

                                        <!--UNITS SECTION-->
                                        <v-container v-if="searchFilter['id'] === 'units'" class="filter-color pa-0" fluid>
                                            <v-card class="filter-row-color my-3 search-value-col" outlined>
                                                <v-row no-gutters justify="start">
                                                    <!--                                                                    <v-col
                                                                                                                                            class="search-value-col mr-12"
                                                                                                                                            cols="auto"
                                                                                                                                            >  
                                                                                                                                            <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['speeches']" label="Discursos"></v-checkbox>
                                                                                                                                            <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['dialogs']" label="Diálogos"></v-checkbox>
                                                                                                                                            <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['interventions']" label="Intervenciones"></v-checkbox>
                                                                                                                                            <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['turns']" label="Turnos"></v-checkbox>
                                                                                                                                            <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['acts']" label="Actos"></v-checkbox>
                                                                                                                                        </v-col>-->
                                                    <v-col class="search-value-col" cols="auto">
                                                        <v-row no-gutters justify="start">
                                                            <v-col cols="12">
                                                                Tipo de subacto
                                                            </v-col>
                                                            <v-col cols="auto" class="ml-6">
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSSD']"
                                                                    label="SSD"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSSS']"
                                                                    label="SSS"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSSTop']"
                                                                    label="SSTop"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSSX']"
                                                                    label="SSX"></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="auto ml-10">
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAT']"
                                                                    label="SAT"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAT_M']"
                                                                    label="SAT/M"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAT_I']"
                                                                    label="SAT/I"></v-checkbox>
                                                                <!--                                                                                ----
                                                                                <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['subactsSS_SSA']" label="SS/SA"></v-checkbox>
                                                                                <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAX']" label="SAX"></v-checkbox>
                                                                                <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['units']['residue']" label="Residuo"></v-checkbox>-->
                                                            </v-col>
                                                            <v-col cols="auto ml-10">
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAM']"
                                                                    label="SAM"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAM_T']"
                                                                    label="SAM/T"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAM_I']"
                                                                    label="SAM/I"></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="auto ml-10">
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAI']"
                                                                    label="SAI"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAI_T']"
                                                                    label="SAI/T"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAI_M']"
                                                                    label="SAI/M"></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="auto ml-10">
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSS_SSA']"
                                                                    label="SS/SA"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['subactsSAX']"
                                                                    label="SAX"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['residue']"
                                                                    label="Residuo"></v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card class="filter-row-color my-3 search-value-col" outlined>
                                                <v-row no-gutters justify="start">
                                                    <v-col class="search-value-col" cols="auto">
                                                        <v-row no-gutters justify="start">
                                                            <v-col cols="12">
                                                                Tipo de intervención
                                                            </v-col>
                                                            <v-col cols="auto" class="ml-10">
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['initiative']"
                                                                    label="Iniciativa"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['independent']"
                                                                    label="Independiente"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['reactive']"
                                                                    label="Reactiva"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="defined_searchs[search_index]['filter_form']['units']['reactive_initiative']"
                                                                    label="Reactivo-iniciativa"></v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-container>

                                        <!--PROSODY SECTION-->
                                        <v-container v-if="searchFilter['id'] === 'prosody'" fluid
                                            class="filter-color pa-0">
                                            <!--                                                            <v-card 
                                                                                                                            outlined
                                                                                                                            class="filter-row-color my-3 search-value-col"
                                                                                                                            >
                                                                                                                            <v-col
                                                                                                                                cols="12">  
                                                                                                                                <v-checkbox dense hide-details="true" v-model="defined_searchs[search_index]['filter_form']['prosody']['annotation']" label="Grupo entonativo"></v-checkbox>
                                                                                                                            </v-col>
                                                                                                                        </v-card>-->
                                            <v-card outlined class="filter-row-color my-3 pa-1">
                                                <v-col class="search-label-col" cols="12">
                                                    Inflexión final
                                                </v-col>
                                                <v-row no-gutters justify="start">
                                                    <v-col cols="auto" class="ml-10" style="padding:0px !important">
                                                        <v-checkbox dense hide-details="true"
                                                            v-model="defined_searchs[search_index]['filter_form']['prosody']['tone_ascending']"
                                                            label="Ascendente"></v-checkbox>
                                                        <v-checkbox dense hide-details="true"
                                                            v-model="defined_searchs[search_index]['filter_form']['prosody']['tone_descending']"
                                                            label="Descendente"></v-checkbox>
                                                    </v-col>
                                                    <v-col cols="auto" class="ml-10" style="padding:0px !important">
                                                        <v-checkbox dense hide-details="true"
                                                            v-model="defined_searchs[search_index]['filter_form']['prosody']['tone_suspended']"
                                                            label="Suspendido"></v-checkbox>
                                                        <v-checkbox dense hide-details="true"
                                                            v-model="defined_searchs[search_index]['filter_form']['prosody']['tone_circumflex']"
                                                            label="Circunflejo"></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-container>
                                    </v-tab-item>
                                </v-tabs-items>

                                <v-row no-gutters justify="end">
                                    <v-col cols="auto">
                                        <v-btn class="mr-3" color="white"
                                            @click="doSearch(search_index); filters_dialog = false;" small>
                                            Filtrar
                                        </v-btn>
                                        <v-btn class="mr-3" color="white"
                                            @click="resetFilterSection(search_index, selected_entity_tab.replace('tab-', ''));"
                                            small>
                                            Borrar
                                        </v-btn>
                                        <v-btn class="mr-3" color="white" @click="filters_dialog = false;" small>
                                            Cerrar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-dialog>
    </v-card>
</template>
<script>
import api from '@/restApi';
import { saveAs } from 'file-saver';
import { Loading } from '@/components';
import { WidthType, BorderStyle, Document, Paragraph, Packer, TextRun, Table, TableRow, TableCell, HeadingLevel, ShadingType, AlignmentType } from "docx";
import moment from 'moment';
import ExcelJS from 'exceljs';
//    import axios from 'axios';

//    var searchFilters = {
//        general: {id: "general", name: 'General'},
//        speaker: {id: "speaker", name: 'Hablante'},
//        intervention: {id: "intervention", name: 'Intervención'},
//        pause: {id: "pause", name: 'Pausa'},
//        intonation: {id: "intonation", name: 'Entonación'},
//        annotation: {id: "annotation", name: 'Grupo entonativo'},
//        lexical_distance: {id: "lexical_distance", name: 'Distancia léxica'}
//    };

export default {
    name: 'chronologic-search',
    components: {
        Document, Paragraph, Packer, TextRun, Table, TableRow, TableCell, HeadingLevel, ShadingType, saveAs, BorderStyle, WidthType, AlignmentType,
        [Loading.name]: Loading
    },
    props: {
    },
    data() {
        return {
            debugTextSearch: false,
            player: null,
            // Tabs management
            visualization_dialog: false,
            filters_dialog: false,
            selected_entity_tab: "tab-options",
            search_words: undefined,
            search_text: undefined,
            search_regex: "",
            search_regex_default: "(?<=\\b)(?<![àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ])$text(?=\\s|$|\\.|\\:|,|-|\\¿|\\?|¡|!|\\/|↑|↓|→|º|[|]|\\(|\\)|<)",
            context_ratio: 2,
            alternate: true,
            audio_is_loading: true,
            selected_result_item: null,
            expanded: [],
            conversations: [],
            conversation_types: [],
            age_ranges: [],
            age_ranges_2: [],
            educational_levels: [],
            search_filters: [
                {
                    id: "options",
                    name: 'Opciones',
                    icon: "mdi-access-point"
                },
                {
                    id: "metadata",
                    name: 'Metadatos',
                    icon: "record_voice_over"
                },
                {
                    id: "prosody",
                    name: 'Prosodia',
                    icon: "g_translate"
                },
                {
                    id: "units",
                    name: 'Unidades',
                    icon: "font_download"
                }
            ],
            visualizationForm: {
                units: {
                    speeches: false,
                    dialogs: false,
                    turns: false,
                    acts: false,
                    subacts: false
                }
            },
            defined_searchs: [],
            search_index: 0,
            /** TABLE VARIABLES **/
            result_headers: [
                {
                    text: "",
                    sortable: false
                },
                {
                    text: "Conv",
                    align: "center",
                    value: "conversation_speaker.conversation.name",
                    width: 90
                },
                {
                    text: "Tipo",
                    align: "center",
                    value: "intervention_type",
                    width: 90
                },
                {
                    text: "Habl.",
                    align: "center",
                    value: "speaker",
                    sortable: false,
                    width: 100
                },
                //                    {
                //                        text: "Id",
                //                        align: "start",
                //                        sortable: "false",
                //                        value: "cod"
                //                    },
                {
                    text: "Contenido",
                    align: "center",
                    value: "metadata_contents"
                }
                // {
                //     text: "",
                //     sortable: false,
                //     width: 60
                // }
            ],
            result_items: [],
            selected_rows: [],
            pagination: [],
            result_tokens_count: 0,
            result_conversation_count: [],
            loading_results: [],

            // ++++++++++++++++++++++++++++++++++

            word_types: [
                "Abreviatura",
                "Adjetivo",
                "Adverbio",
                //                    "Cifra",
                "Conjunción",
                "Determinante",
                "Expresión adjetiva",
                //                    "Fecha",
                //                    "Hora",
                "Interjección",
                "No Categorizada",
                "Pronombre",
                "Palabra cortada",
                //                    "Pausa",
                "Preposición",
                "Sigla",
                //                    "Signo de puntuación",
                "Sustantivo",
                "Verbo"
            ],
            numbers: [
                "Singular",
                "Plural",
                "Plural/Singular"
            ],
            genres: [
                "Femenino",
                "Masculino",
                "Masculino/Femenino",
                "Masculino/Femenino/Neutro",
                "Masculino/Neutro",
                "Neutro"
            ],
            persons: [
                "1ª persona del singular",
                "2ª persona del singular",
                "3ª persona del singular",
                "1ª persona del plural",
                "2ª persona del plural",
                "3ª persona del plural"
            ],
            tenses: [
                "Copretérito",
                "Copretérito en -se",
                "Futuro",
                "Pospretérito",
                "Presente",
                "Presente/Pretérito",
                "Pretérito"
            ],
            moods: [
                "Gerundio",
                "Indicativo",
                "Imperativo",
                "Imperativo/Indicativo",
                "Imperativo/Subjuntivo",
                "Infinitivo",
                "Participio",
                "Subjuntivo"
            ],
            sust_types: [
                "Común",
                "Propio"
            ],
            pronom_types: [
                "Comparativo",
                "Cuantificador",
                "Demostrativo",
                "Distributivo",
                "Exclamativo o interrogativo",
                "Globalizador",
                "Indefinido",
                "Numeral cardinal",
                "Numeral ordinal",
                "Numeral partitivo",
                "Personal",
                "Posesivo",
                "Reflexivo",
                "Relativo"
            ],
            pronom_subtypes: [
                "Acusativo",
                "Acusativo/Predicativo",
                "Dativo",
                "Dativo/Medio",
                "Nominativo",
                "Nominativo/Preposicional",
                "Singular preposicional"
            ],
            det_types: [
                "Artículo determinado",
                "Artículo indeterminado",
                "Comparativo",
                "Cuantificador",
                "Demostrativo",
                "Exclamativo o interrogativo",
                "Distributivo",
                "Globalizador",
                "Indefinido",
                "Numeral cardinal",
                "Numeral ordinal",
                "Numeral partitivo",
                "Posesivo",
                "Reflexivo",
                "Relativo"
            ],
            itemWithSelectedContext: null,
            result_card_cols: 6,
            detail_card_cols: 6,
            error_message: []
        };
    },
    created: function () {
        this.search_regex = this.search_regex_default;

        // Initialize filter forms
        this.defined_searchs.push(this.initializeSearch());
        this.defined_searchs.push(this.initializeSearch());

        this.loadConversations();
        this.loadConversationTypes();
        this.loadAgeRanges();
        this.loadAgeRanges2();
        this.loadEducationalLevels();
        // this.doSearch(0);
    },
    mounted: function () {
        //            this.$validator.localize('es', this.dictionary);
    },
    watch: {
        selected_entity_tab: function () {
            this.current_search_section = "tab-general";
            //                this.doSearch();
        }
        // pagination: function (item) {
        //     var self = this;

        //     // self.resetContextDetails();
        // }
    },
    methods: {
        initializeSearch() {
            return {
                error: "",
                result_headers: [
                    {
                        text: "",
                        sortable: false
                    },
                    {
                        text: "Conv",
                        align: "center",
                        value: "conversation_speaker.conversation.name",
                        width: 90
                    },
                    {
                        text: "Tipo",
                        align: "center",
                        value: "intervention_type",
                        width: 90
                    },
                    {
                        text: "Habl.",
                        align: "center",
                        value: "speaker",
                        sortable: false,
                        width: 100
                    },
                    //                    {
                    //                        text: "Id",
                    //                        align: "start",
                    //                        sortable: "false",
                    //                        value: "cod"
                    //                    },
                    {
                        text: "Contenido",
                        align: "center",
                        value: "metadata_contents"
                    }
                    // {
                    //     text: "",
                    //     sortable: false,
                    //     width: 60
                    // }
                ],
                result_items: [],
                selected_rows: [],
                pagination: {},
                result_conversation_count: 0,
                result_tokens_count: 0,
                loading_results: false,
                filter_form: {
                    conv_year_init: undefined,
                    conv_year_end: undefined,
                    options: {
                        token_mode: "shape",
                        capital_mode: undefined,
                        accents: undefined,
                        filter_position: undefined,
                        filter_position_char: undefined
                    },
                    metadata: {
                        conversation: undefined,
                        conversation_type: undefined,
                        speaker_age_range: undefined,
                        speaker_age_range_2: undefined,
                        speaker_genre: undefined,
                        speaker_educational_level: undefined
                    },
                    units: {
                        subactsSSD: false,
                        subactsSSS: false,
                        subactsSSTop: false,
                        subactsSSX: false,
                        subactsSAT: false,
                        subactsSAI: false,
                        subactsSAX: false,
                        initiative: false,
                        reactive: false,
                        reactive_initiative: false,
                        independent: false
                    },
                    prosody: {
                        tone_ascending: false,
                        tone_descending: false,
                        tone_suspended: false,
                        tone_circumflex: false
                    }
                }
            };
        },
        async loadInterventionDetails({ item }) {
            var elementId, conversationCode;
            var start, duration;
            var isExpanding;
            var self = this;

            self.audio_is_loading = true;

            self.alternate = !self.alternate;

            //                console.log("Expanded item", item);
            //                console.log("Element", "plyr_inter_" + item["cod"]);
            //                console.log("Expanded", self.expanded)
            isExpanding = !this.$mout.array.filter(self.expanded, function (item2) {
                //                    console.log(item2["cod"], "-", item["cod"])
                return item2["cod"] === item["cod"];
            }).length > 0;
            //                console.log("EOOOOOOOOOOOOOOOOOOOOO", isExpanding)

            if (this.$mout.lang.isEmpty(item) || !isExpanding) {
                return;
            }

            elementId = "#audio_player";
            conversationCode = item["cod"].substring(0, item["cod"].indexOf("-"));
            start = item["start"];
            duration = item["end"] - item["start"];

            if (self.player) {
                self.player.destroy();
            }

            setTimeout(async function () {
                self.player = await self.loadPlayer(elementId, conversationCode, start, duration, self.audio_is_loading);

                self.audio_is_loading = false;
            }, 10);
        },
        isSustTypeVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Sustantivo"
            );
        },
        isDetTypeVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Determinante"
            );
        },
        isPronomTypeVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Pronombre"
            );
        },
        isPronomSubtypeVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Pronombre"
            );
        },
        isPersonVisible(searchIndex) {
            return (
                (this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Determinante" && this.defined_searchs[searchIndex]['filter_form']['options']['type'] === "Posesivo") ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Verbo" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Pronombre"
            );
        },
        isGenreVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Adjetivo" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Determinante" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Sustantivo" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Pronombre"
            );
        },
        isNumberVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Adjetivo" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Determinante" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Sustantivo" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Pronombre" ||
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Verbo"
            );
        },
        isTenseVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Verbo"
            );
        },
        isMoodVisible(searchIndex) {
            return (
                this.defined_searchs[searchIndex]['filter_form']['options']['word_type'] === "Verbo"
            );
        },
        async loadAgeRanges() {
            var queryString;

            queryString = "fields=cod,name,description&sort={\"field\":\"order\",\"dir\":\"asc\"}";

            // Get all
            this.age_ranges = await api.getAllItems("age_ranges", queryString);
        },
        async loadAgeRanges2() {
            var queryString;

            queryString = "fields=cod,name,description&sort={\"field\":\"order\",\"dir\":\"asc\"}";

            // Get all
            this.age_ranges_2 = await api.getAllItems("age_range_2", queryString);
        },
        async loadEducationalLevels() {
            var queryString;

            queryString = "fields=cod,name,description&sort={\"field\":\"order\",\"dir\":\"asc\"}";

            // Get all sections for page
            this.educational_levels = await api.getAllItems("educational_levels", queryString);
        },
        async loadConversations() {
            var queryString;

            queryString = "fields=cod,name&sort={\"field\":\"name\",\"dir\":\"asc\"}";

            // Get all sections for page
            this.conversations = await api.getAllItems("conversations", queryString);
        },
        async loadConversationTypes() {
            var queryString;

            queryString = "fields=cod,name&sort={\"field\":\"order\",\"dir\":\"asc\"}";

            // Get all sections for page
            this.conversation_types = await api.getAllItems("conversation_types", queryString);
        },
        async doTextSearch() {
            if (this.$mout.lang.isEmpty(this.search_words)) {
                return;
            }

            // Set the search word
            this.search_text = this.search_words;

            // Search text has changed. Execute all defined searchs
            for (var i = 0; i < this.defined_searchs.length; i++) {
                this.doSearch(i);
            }
        },
        async doSearch(searchIndex) {
            var initYear, endYear;

            if (this.$mout.lang.isEmpty(this.search_words)) {
                return;
            }

            // Set the search word
            this.search_text = this.search_words;

            this.defined_searchs[searchIndex]["error"] = "";

            initYear = this.$mout.lang.toNumber(this.defined_searchs[searchIndex]["filter_form"]["conv_year_init"]);
            endYear = this.$mout.lang.toNumber(this.defined_searchs[searchIndex]["filter_form"]["conv_year_end"]);

            if (!this.$mout.lang.isNumber(initYear) ||
                !this.$mout.lang.isNumber(endYear) || initYear <= 0 || endYear <= 0) {
                if (searchIndex <= 0) {
                    this.defined_searchs[searchIndex]["error"] = "Debe de establecer un rango de años que acoten la búsqueda principal.";
                } else {
                    this.defined_searchs[searchIndex]["error"] = "Debe de establecer un rango de años que acoten la búsqueda a comparar.";
                }
                return;
            }

            this.defined_searchs[searchIndex]["result_items"] = [];
            this.defined_searchs[searchIndex]["result_items"] = [];
            this.defined_searchs[searchIndex]["loading_results"] = true;

            this.searchInterventions(searchIndex);
        },
        async searchInterventions(searchIndex) {
            var fields, sort, populate, queryString;
            var visualizationOptions;
            var conversationsMap;
            var debug;
            var self = this;

            // Reset selection
            self.selected_rows = [];

            queryString = self.createQueryString(searchIndex);

            visualizationOptions = "turns,interventions";

            //                fields = "cod,conversation,dialog,start,end,order,conversation_speaker,metadata_contents,turn_number,intervention_number,intervention_type,is_turn,is_observation,is_direct_speech,is_residue";
            fields = "cod,conversation,conversation_speaker,metadata_contents,plain_contents,intervention_type,is_observation,is_direct_speech,search_data, intervention_number,turn_number,intervention_key";
            populate = "conversation_speaker.conversation.cod,conversation_speaker.conversation.name,conversation_speaker.conversation.is_segmented,conversation_speaker.conversation.conversation_type,intervention_type.key";
            sort = "{\"field\":\"order\",\"dir\":\"asc\"}";

            queryString += "&populate=" + populate;
            if (!self.$mout.lang.isEmpty(fields)) {
                queryString += "&fields=" + fields;
            }
            queryString += "&sort=" + sort + "&limit=0";

            if (self.debugTextSearch) {
                self.search_regex = "$text(?=\\s|$|\\.|\\:|,|-|\\¿|\\?|¡|!|\\/|↑|↓|→|º|[|]|\\(|\\))";
            } else {
                self.search_regex = self.search_regex_default;
            }

            debug = "";
            //                self.defined_searchs[searchIndex]['filter_form']["metadata"]["conversation"] = "046";
            //                visualizationOptions = "turns,interventions";
            //                if (self.defined_searchs[searchIndex]['filter_form']["metadata"]["conversation"] === "046") {
            visualizationOptions = "speeches,dialogs,turns,interventions,acts,subacts";
            debug = "&debug=true";
            //                }

            queryString += "&regex=" + encodeURI(self.search_regex) + "&segmentation_options=" + visualizationOptions + debug;

            // Get all sections for page
            self.defined_searchs[searchIndex]["result_items"] = await api.getAllItems("search_interventions", queryString);

            self.defined_searchs[searchIndex]["result_tokens_count"] = 0;

            conversationsMap = {};
            for (var i in self.defined_searchs[searchIndex]["result_items"]) {
                self.defined_searchs[searchIndex]["result_items"][i]["highlight"] = true;

                if (self.defined_searchs[searchIndex]["result_items"][i]["search_data"]) {
                    self.defined_searchs[searchIndex]["result_tokens_count"] += self.defined_searchs[searchIndex]["result_items"][i]["search_data"]["tokens_parsed"].length;
                }

                if (self.$mout.lang.isEmpty(conversationsMap[self.defined_searchs[searchIndex]["result_items"][i]["conversation"]["cod"]])) {
                    conversationsMap[self.defined_searchs[searchIndex]["result_items"][i]["conversation"]["cod"]] = 0;
                } else {
                    conversationsMap[self.defined_searchs[searchIndex]["result_items"][i]["conversation"]["cod"]]++;
                }
            }

            this.defined_searchs[searchIndex]["result_conversation_count"] = self.$mout.object.keys(conversationsMap).length;

            this.defined_searchs[searchIndex]["loading_results"] = false;
        },
        createQueryString(searchIndex) {
            var queryString, toneFilter, subactTypeFilter, interventionTypeFilter;
            var self = this;

            queryString = "search=" + self.search_text;

            // Format options filter
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["token_mode"]) {
                queryString += "&token_mode=" + self.defined_searchs[searchIndex]['filter_form']["options"]["token_mode"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["capital_mode"]) {
                queryString += "&capital_mode=" + self.defined_searchs[searchIndex]['filter_form']["options"]["capital_mode"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["accents"]) {
                queryString += "&accents=" + self.defined_searchs[searchIndex]['filter_form']["options"]["accents"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position"]) {
                queryString += "&filter_position=" + self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position_char"]) {
                queryString += "&filter_position_char=" + self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position_char"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["word_type"]) {
                queryString += "&word_type=" + self.defined_searchs[searchIndex]['filter_form']["options"]["word_type"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["type"]) {
                queryString += "&type=" + self.defined_searchs[searchIndex]['filter_form']["options"]["type"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["subtype"]) {
                queryString += "&subtype=" + self.defined_searchs[searchIndex]['filter_form']["options"]["subtype"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["person"]) {
                queryString += "&person=" + self.defined_searchs[searchIndex]['filter_form']["options"]["person"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["genre"]) {
                queryString += "&genre=" + self.defined_searchs[searchIndex]['filter_form']["options"]["genre"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["number"]) {
                queryString += "&number=" + self.defined_searchs[searchIndex]['filter_form']["options"]["number"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["options"]["mood"]) {
                queryString += "&mood=" + self.defined_searchs[searchIndex]['filter_form']["options"]["mood"];
            }
            // Format metadata filter
            if (self.defined_searchs[searchIndex]['filter_form']["metadata"]["conversation"]) {
                queryString += "&conv=" + self.defined_searchs[searchIndex]['filter_form']["metadata"]["conversation"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["metadata"]["conversation_type"]) {
                queryString += "&conversation_speaker.conversation.conversation_type.cod=" + self.defined_searchs[searchIndex]['filter_form']["metadata"]["conversation_type"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_age_range"]) {
                queryString += "&conversation_speaker.age_range.cod=" + self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_age_range"]["cod"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_age_range_2"]) {
                queryString += "&conversation_speaker.age_range_2.cod=" + self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_age_range_2"]["cod"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_educational_level"]) {
                queryString += "&conversation_speaker.educational_level.cod=" + self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_educational_level"]["cod"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_genre"]) {
                queryString += "&conversation_speaker.genre=" + self.defined_searchs[searchIndex]['filter_form']["metadata"]["speaker_genre"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["conv_year_init"]) {
                queryString += "&conv_year_init=" + self.defined_searchs[searchIndex]['filter_form']["conv_year_init"];
            }
            if (self.defined_searchs[searchIndex]['filter_form']["conv_year_end"]) {
                queryString += "&conv_year_end=" + self.defined_searchs[searchIndex]['filter_form']["conv_year_end"];
            }
            // Format units filter
            toneFilter = "";
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_ascending"]) {
                toneFilter += "ascending,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_descending"]) {
                toneFilter += "descending,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_suspended"]) {
                toneFilter += "suspended,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_circumflex"]) {
                toneFilter += "circumflex,";
            }
            if (!self.$mout.lang.isEmpty(toneFilter)) {
                //                    console.log(toneFilter);
                toneFilter = self.$mout.string.rtrim(toneFilter, ",");
                queryString += "&tone=" + toneFilter;
            }

            subactTypeFilter = "";
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAX"]) {
                subactTypeFilter += "SAX,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAT_M"]) {
                subactTypeFilter += "SAT_M,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAT_I"]) {
                subactTypeFilter += "SAT_I,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAM_T"]) {
                subactTypeFilter += "SAM_T,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAI"]) {
                subactTypeFilter += "SAI,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAI_T"]) {
                subactTypeFilter += "SAI_T,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSSX"]) {
                subactTypeFilter += "SSX,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAM_I"]) {
                subactTypeFilter += "SAM_I,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAI_M"]) {
                subactTypeFilter += "SAI_M,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSS_SSA"]) {
                subactTypeFilter += "SS_SA,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["residue"]) {
                subactTypeFilter += "residue,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSSS"]) {
                subactTypeFilter += "SSS,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAI"]) {
                subactTypeFilter += "SAI,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAM"]) {
                subactTypeFilter += "SAM,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSS_SA"]) {
                subactTypeFilter += "SS_SA,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSSTop"]) {
                subactTypeFilter += "SSS_Top,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSAT"]) {
                subactTypeFilter += "SAT,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["subactsSSD"]) {
                subactTypeFilter += "SSD";
            }
            if (!self.$mout.lang.isEmpty(subactTypeFilter)) {
                //                    console.log(subactTypeFilter)
                subactTypeFilter = self.$mout.string.rtrim(subactTypeFilter, ",");
                queryString += "&subact_type=" + subactTypeFilter;
            }

            interventionTypeFilter = "";
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["initiative"]) {
                interventionTypeFilter += "i,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["reactive"]) {
                interventionTypeFilter += "r,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["reactive_initiative"]) {
                interventionTypeFilter += "ri,";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["units"]["independent"]) {
                interventionTypeFilter += "ind";
            }
            if (!self.$mout.lang.isEmpty(interventionTypeFilter)) {
                //                    console.log(interventionTypeFilter)
                interventionTypeFilter = self.$mout.string.rtrim(interventionTypeFilter, ",");
                queryString += "&intervention_type=" + interventionTypeFilter;
            }


            return queryString;
        },
        highlight(searchIndex, item) {
            var contents;
            var searchText, regexString;
            var searchFilter;
            var toneChar, wordBoundaryCharPreffix, wordBoundaryCharSuffix;
            var self = this;

            contents = item["metadata_contents"];

            searchText = self.search_text;

            if (self.$mout.lang.isEmpty(searchText) || !item["highlight"]) {
                return contents;
            }

            regexString = self.search_regex_default;

            //                console.log(item["cod"] + regexString);

            wordBoundaryCharPreffix = regexString.substring(0, regexString.indexOf(")$text", 20) + 1);
            wordBoundaryCharSuffix = regexString.substring(regexString.lastIndexOf("(?"), regexString.length);

            //                console.log("Prefix:", wordBoundaryCharPreffix)
            //                console.log("Suffix:", wordBoundaryCharSuffix)

            // Format units filter
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_ascending"]) {
                toneChar = "↑";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_descending"]) {
                toneChar = "↓";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_suspended"]) {
                toneChar = "→";
            }
            if (self.defined_searchs[searchIndex]['filter_form']["prosody"]["tone_circumflex"]) {
                toneChar = "^";
            }

            // If there is search data
            if (!this.$mout.lang.isEmpty(item["search_data"])) {
                searchFilter = "";
                for (var i in item["search_data"]["tokens_parsed"]) {
                    searchText = self.escapeRegExp(item["search_data"]["tokens_parsed"][i]);

                    if (!this.$mout.lang.isEmpty(toneChar)) {
                        searchFilter += wordBoundaryCharPreffix + "" + searchText + toneChar + "|";
                        searchFilter += wordBoundaryCharPreffix + "" + searchText + "." + toneChar + "|";
                        searchFilter += wordBoundaryCharPreffix + "" + searchText + ".." + toneChar + "|";
                        searchFilter += wordBoundaryCharPreffix + "" + searchText + "..." + toneChar + "|";
                    } else {
                        searchFilter += wordBoundaryCharPreffix + searchText + wordBoundaryCharSuffix + "|";
                    }
                }

                searchFilter = this.$mout.string.rtrim(searchFilter, "|");
                //                    searchFilter = searchFilter.substring(0, searchFilter.length - 1);
            } else {
                if (!this.$mout.lang.isEmpty(toneChar)) {
                    searchFilter = wordBoundaryCharPreffix + "" + searchText + toneChar + "|";
                    searchFilter += wordBoundaryCharPreffix + "" + searchText + "." + toneChar + "|";
                    searchFilter += wordBoundaryCharPreffix + "" + searchText + ".." + toneChar + "|";
                    searchFilter += wordBoundaryCharPreffix + "" + searchText + "..." + toneChar;
                } else {
                    var regExpression = regexString.replace("$text", searchText);

                    searchFilter = regExpression;
                }
            }

            //                if (self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position"] === "preceded" ||
            //                        self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position"] === "both") {
            //                    switch (self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position_char"]) {
            //                        case "exclamation_mark":
            //                            filterPositionCondition["token.prefix"] = "¡";
            //                            break;
            //                        case "question_marks":
            //                            filterPositionCondition["token.prefix"] = "¿";
            //                            break;
            //                        case "slash":
            //                            filterPositionCondition["token.prefix"] = "/";
            //                            break;
            //                    }
            //                }
            //                if (self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position"] === "followed" ||
            //                        self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position"] === "both") {
            //                    switch (self.defined_searchs[searchIndex]['filter_form']["options"]["filter_position_char"]) {
            //                        case "exclamation_mark":
            //                            filterPositionCondition["token.suffix"] = "!";
            //                            break;
            //                        case "question_marks":
            //                            filterPositionCondition["token.suffix"] = "?";
            //                            break;
            //                        case "slash":
            //                            filterPositionCondition["token.suffix"] = "/";
            //                            break;
            //                    }
            //                }

            ////                console.log("-------------------------");
            // console.log(item["cod"], searchFilter);
            // console.log(item["cod"], this.search_text);
            // console.log(contents);


            //                return contents.replace(new RegExp(this.search_text, "gi"), match => {
            return contents.replace(new RegExp(searchFilter, "gm"), match => {
                return '<span class="highlightText">' + match + '</span>';
            });
        },
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        },
        async checkSelected(selectedItem) {
            if (this.selected_rows.length <= 0) {
                return;
            }

            //                this.updateDetailedView(selectedItem);
        },
        async resetFilters(searchIndex) {
            var self = this;

            self.resetFilterSection(searchIndex, "options");
            self.resetFilterSection(searchIndex, "metadata");
            self.resetFilterSection(searchIndex, "prosody");
            self.resetFilterSection(searchIndex, "units");
        },
        async resetFilterSection(searchIndex, section) {
            switch (section) {
                case "options":
                    this.defined_searchs[searchIndex]['filter_form']["options"] = {
                        token_mode: "shape",
                        capital_mode: null,
                        accents: null,
                        filter_position: null,
                        filter_position_char: null,
                        word_type: null,
                        type: null,
                        subtype: null,
                        person: null,
                        genre: null,
                        number: null,
                        mood: null,
                        tense: null
                    };
                    break;
                case "metadata":
                    this.defined_searchs[searchIndex]['filter_form']["metadata"] = {
                        conversation: null,
                        speaker_age_range: null,
                        speaker_age_range_2: null,
                        speaker_genre: null,
                        speaker_educational_level: null
                    };
                    break;
                case "units":
                    this.defined_searchs[searchIndex]['filter_form']["units"] = {
                        speeches: false,
                        dialogs: false,
                        turns: false,
                        interventions: false,
                        acts: false,
                        subactsSSD: false,
                        subactsSSS: false,
                        subactsSSTop: false,
                        subactsSSX: false,
                        subactsSAT: false,
                        subactsSAI: false,
                        subactsSAX: false
                    };
                    break;
                case "prosody":
                    this.defined_searchs[searchIndex]['filter_form']["prosody"] = {
                        tone_ascending: false,
                        tone_descending: false,
                        tone_suspended: false,
                        tone_circumflex: false
                    };
                    break;
            }
        },
        async resetWordTypeSection(searchIndex) {
            this.defined_searchs[searchIndex]['filter_form']["options"]["subtype"] = null;
            this.defined_searchs[searchIndex]['filter_form']["options"]["type"] = null;
            this.defined_searchs[searchIndex]['filter_form']["options"]["person"] = null;
            this.defined_searchs[searchIndex]['filter_form']["options"]["genre"] = null;
            this.defined_searchs[searchIndex]['filter_form']["options"]["number"] = null;
            this.defined_searchs[searchIndex]['filter_form']["options"]["mood"] = null;
            this.defined_searchs[searchIndex]['filter_form']["options"]["tense"] = null;
            //                console.log(this.defined_searchs[searchIndex]['filter_form']["options"])
        },
        async resetVisualizationSection() {
            this.visualizationForm["units"] = {
                speeches: true,
                dialogs: true,
                //                    interventions: true,
                turns: true,
                acts: true,
                subacts: true
            };
        },
        generateResultDoc(resultRows, index) {
            var titleText, explanationText, summaryText, currentDate;
            var docObject;
            var tableRows;
            var tableCells;
            var columnWidths;
            var lines;
            var self = this;

            if (resultRows.lenght <= 0) {
                return;
            }

            currentDate = moment().locale("es").format("D MMMM YYYY");

            titleText = "Resultados de la búsqueda";

            docObject = {
                sections: [{
                    properties: {},
                    children: [
                    ]
                }]
            };

            lines = [
                new TextRun({
                    text: titleText,
                    bold: true,
                    color: "da8d2b",
                    size: 40,
                    heading: HeadingLevel.TITLE
                })
            ];

            explanationText = "Listado de " + self.defined_searchs[index]['result_items'].length +
                " resultados encontrados en " + self.defined_searchs[index]['result_conversation_count'] +
                " conversaciones para el texto de búsqueda: '" + self.search_text + "'";
            //                footerText = "* Cita: Pons Bordería, Salvador (dir.) - Corpus Val.Es.Co 3.0. <http://www.valesco.es> [" + currentDate + "]";
            summaryText = this.getSearchSummary(self.defined_searchs[index]["filter_form"]);

            docObject["sections"][0]["children"].push(new Paragraph({
                alignment: AlignmentType.CENTER,
                spacing: {
                    after: 200
                },
                children: lines
            }));

            lines = [
                new TextRun({
                    text: explanationText
                })
            ];

            docObject["sections"][0]["children"].push(new Paragraph({
                alignment: AlignmentType.LEFT,
                spacing: {
                    after: 200
                },
                children: lines
            }));

            // self.printDocSearchSummary(self.defined_searchs[index]["filter_form"], docObject);
            // Print each summary text line delimited by \n
            summaryText.split("\n").forEach(function (line) {
                self.addNewLine(line.replace("\n", ""), docObject)
            });

            tableRows = [];
            columnWidths = [1800, 800, 1200, 5000];

            var padding = 200;

            tableCells = [
                new TableCell({
                    shading: {
                        fill: "000000",
                        type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                        color: "auto"
                    },
                    margins: {
                        top: padding,
                        bottom: padding,
                        left: padding,
                        right: padding
                    },
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "CONVERSACIÓN",
                                    font: "Arial",
                                    size: 18,
                                    color: "da8d2b"
                                })]
                        })
                    ]
                }),
                new TableCell({
                    shading: {
                        fill: "000000",
                        type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                        color: "auto",
                    },
                    margins: {
                        top: padding,
                        bottom: padding,
                        left: padding,
                        right: padding
                    },
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "TIPO",
                                    font: "Arial",
                                    size: 18,
                                    color: "da8d2b"
                                })]
                        })
                    ]
                }),
                new TableCell({
                    shading: {
                        fill: "000000",
                        type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                        color: "auto",
                    },
                    margins: {
                        top: padding,
                        bottom: padding,
                        left: padding,
                        right: padding
                    },
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "HABLANTE",
                                    font: "Arial",
                                    size: 18,
                                    color: "da8d2b"
                                })]
                        })
                    ]
                }),
                new TableCell({
                    shading: {
                        fill: "000000",
                        type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                        color: "auto",
                    },
                    margins: {
                        top: padding,
                        bottom: padding,
                        left: padding,
                        right: padding
                    },
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "CONTENIDO",
                                    font: "Arial",
                                    size: 18,
                                    color: "da8d2b"
                                })]
                        })
                    ]
                })
            ];

            tableRows.push(new TableRow({
                children: tableCells
            }));

            this.$mout.array.forEach(resultRows, function (item, index, arr) {
                var turnNumber, interventionNumber, interventionKey;
                //                    var startIndex;


                //                    console.log(index, ' : ', item);

                //                    var startIndex = item['conversation_speaker'].indexOf("speaker_key") + 2;
                //                    interventionKey = item['conversation_speaker'].substring(startIndex, item['conversation_speaker'].indexOf("span", startIndex) - 2);
                interventionKey = item['intervention_key'];
                turnNumber = item['turn_number'];
                interventionNumber = item['intervention_number'];


                padding = 100;

                tableCells = [
                    new TableCell({
                        margins: {
                            top: padding,
                            bottom: padding,
                            left: padding,
                            right: padding
                        },
                        children: [
                            new Paragraph({
                                text: item["conversation"]["name"],
                                font: "Arial",
                                size: 22,
                                alignment: AlignmentType.CENTER,
                            })
                        ]
                    }),
                    new TableCell({
                        margins: {
                            top: padding,
                            bottom: padding,
                            left: padding,
                            right: padding
                        },
                        children: [
                            new Paragraph({
                                text: item["intervention_type"],
                                font: "Arial",
                                size: 22,
                                alignment: AlignmentType.CENTER,
                            })
                        ]
                    }),
                    new TableCell({
                        margins: {
                            top: padding,
                            bottom: padding,
                            left: padding,
                            right: padding
                        },
                        children: [
                            new Paragraph({
                                text: turnNumber + interventionKey + interventionNumber,
                                font: "Arial",
                                size: 22,
                                alignment: AlignmentType.CENTER,
                            })
                        ]
                    }),
                    new TableCell({
                        margins: {
                            top: padding,
                            bottom: padding,
                            left: padding,
                            right: padding
                        },
                        children: [
                            new Paragraph({
                                text: item["plain_contents"],
                                font: "Arial",
                                size: 22
                            })
                        ]
                    })
                ];

                tableRows.push(new TableRow({
                    children: tableCells
                }));
            });

            let table = new Table(
                {
                    alignment: AlignmentType.CENTER,
                    columnWidths: columnWidths,
                    rows: tableRows
                }
            );

            docObject["sections"][0]["children"].push(table);

            lines = [
                new TextRun(
                    "Pons Bordería, Salvador (dir.): "
                ),
                new TextRun({
                    text: "Corpus Val.Es.Co 3.0. ",
                    italics: true
                }),
                new TextRun({
                    text: "<http://www.valesco.es> [" + currentDate + "]"
                })
            ];

            docObject["sections"][0]["children"].push(new Paragraph({
                alignment: AlignmentType.RIGHT,
                spacing: {
                    before: 100
                },
                children: lines
            }));

            const doc = new Document(docObject);

            Packer.toBlob(doc).then((blob) => {
                //                    console.log(blob);
                saveAs(blob, "example.docx");
                //                    console.log("Document created successfully");
            });
        },
        // printDocSearchSummary(filterForm, docObject) {
        //     var search;

        //     search = "Filtros de búsqueda:";

        //     this.addNewLine(search, docObject);

        //     if (filterForm["conv_year_init"] && filterForm["conv_year_end"]) {
        //         search = "\t+ Conversaciones grabadas entre el " + filterForm["conv_year_init"];
        //         search += " y el " + filterForm["conv_year_end"] + ".";

        //         this.addNewLine(search, docObject);
        //     }

        //     // Format options filter
        //     if (filterForm["options"]["token_mode"]) {
        //         search = "\t+ Buscando por " + (filterForm["options"]["token_mode"] === "shape" ? "forma" : "lema");
        //         search += filterForm["options"]["capital_mode"] ? " teniendo en cuenta mayúsculas" : "";
        //         search += (filterForm["options"]["capital_mode"] && filterForm["options"]["accents"]) ? " y" : "";
        //         search += (filterForm["options"]["accents"]) ? " teniendo en cuenta acentos" : "";

        //         this.addNewLine(search, docObject);
        //     }

        //     if (filterForm["options"]["filter_position"]) {
        //         search = "\t+ Aparece ";
        //         switch (filterForm["options"]["filter_position"]) {
        //             case "preceded":
        //                 search += "precedido";
        //                 break;
        //             case "followed":
        //                 search += "seguido";
        //                 break;
        //             case "both":
        //                 search += "precedido y seguido";
        //                 break;
        //         }

        //         search += " por "

        //         if (filterForm["options"]["filter_position_char"]) {
        //             switch (filterForm["options"]["filter_position_char"]) {
        //                 case "exclamation_mark":
        //                     search += "¡!";
        //                     break;
        //                 case "question_marks":
        //                     search += "¿?";
        //                     break;
        //                 case "slash":
        //                     search += "/";
        //                     break;
        //             }
        //         }

        //         this.addNewLine(search, docObject);
        //     }


        //     if (filterForm["options"]["word_type"]) {
        //         search = "\t+ Clase de palabra: " + filterForm["options"]["word_type"] + ".";

        //         this.addNewLine(search, docObject);
        //     }



        //     if (filterForm["options"]["type"]) {
        //         search = "\t+ De tipo: " + filterForm["options"]["type"] + ".";
        //         this.addNewLine(search, docObject);
        //     }


        //     if (filterForm["options"]["subtype"]) {
        //         search = "\t+ De subtipo: " + filterForm["options"]["subtype"] + ".";

        //         this.addNewLine(search, docObject);
        //     }

        //     if (filterForm["options"]["person"]) {
        //         search = "\t+ Persona: " + filterForm["options"]["person"] + ".";

        //         this.addNewLine(search, docObject);
        //     }
        //     if (filterForm["options"]["genre"]) {
        //         search = "\t+ Con género: " + filterForm["options"]["genre"] + ".";

        //         this.addNewLine(search, docObject);
        //     }

        //     if (filterForm["options"]["number"]) {
        //         search = "\t+ Con número: " + filterForm["options"]["number"] + ".";

        //         this.addNewLine(search, docObject);
        //     }

        //     if (filterForm["options"]["mood"]) {
        //         search = "\t+ De modo: " + filterForm["options"]["mood"] + ".";

        //         this.addNewLine(search, docObject);
        //     }


        //     // Format metadata filter
        //     if (filterForm["metadata"]["conversation"]) {
        //         search = "\t+ Buscando en la conversación " + filterForm["options"]["conversation"] + ".";

        //         this.addNewLine(search, docObject);
        //     }


        //     if (filterForm["metadata"]["conversation_type"]) {
        //         search = "\t+ Buscando en conversaciones de tipo ";
        //         switch (filterForm["metadata"]["conversation_type"]) {
        //             case "cod_prototypical":
        //                 search += "prototípica.";
        //                 break;
        //             case "cod_periferical":
        //                 search += "periférica.";
        //                 break;
        //         }

        //         this.addNewLine(search, docObject);
        //     }


        //     if (filterForm["metadata"]["speaker_genre"]) {
        //         search = "\t+ Buscando en conversaciones en las que aperezca un hablante ";
        //         search += filterForm["metadata"]["speaker_genre"] === "H" ? "hombre." : "mujer.";

        //         this.addNewLine(search, docObject);
        //     }


        //     if (filterForm["metadata"]["speaker_educational_level"]) {
        //         search = "\t+ Buscando en conversaciones con hablantes de nivel educativo ";
        //         switch (filterForm["metadata"]["speaker_educational_level"]["cod"]) {
        //             case "cod_high_level":
        //                 search += "alto.";
        //                 break;
        //             case "cod_medium_level":
        //                 search += "medio.";
        //                 break;
        //             case "cod_primary_level":
        //                 search += "bajo.";
        //                 break;
        //             case "cod_unknown":
        //                 search += "desconocido.";
        //                 break;
        //         }

        //         this.addNewLine(search, docObject);
        //     }


        //     if (filterForm["metadata"]["speaker_age_range"]) {
        //         search = "\t+ Buscando en conversaciones con hablantes ";
        //         switch (filterForm["metadata"]["speaker_age_range"]["cod"]) {
        //             case "cod_age_range_1":
        //                 search += "de edades comprendidas entre los 18 y 34 años.";
        //                 break;
        //             case "cod_age_range_2":
        //                 search += "de edades comprendidas entre los entre 35 y 54 años.";
        //                 break;
        //             case "cod_age_range_3":
        //                 search += "de más de 55 años.";
        //                 break;
        //         }

        //         this.addNewLine(search, docObject);
        //     }


        //     if (filterForm["metadata"]["speaker_age_range_2"]) {
        //         search = "\t+ Buscando en conversaciones con hablantes ";
        //         switch (filterForm["metadata"]["speaker_age_range_2"]["cod"]) {
        //             case "cod_age_range_2_1":
        //                 search += "de edades comprendidas entre los 18 y 34 años.";
        //                 break;
        //             case "cod_age_range_2_2":
        //                 search += "de edades comprendidas entre los entre 35 y 64 años.";
        //                 break;
        //             case "cod_age_range_2_3":
        //                 search += "de más de 64 años.";
        //                 break;
        //         }

        //         this.addNewLine(search, docObject);
        //     }

        //     // Format units filter
        //     var toneFilter = "";
        //     if (filterForm["prosody"]["tone_ascending"]) {
        //         toneFilter += "ascendente, ";
        //     }
        //     if (filterForm["prosody"]["tone_descending"]) {
        //         toneFilter += "descendente, ";
        //     }
        //     if (filterForm["prosody"]["tone_suspended"]) {
        //         toneFilter += "suspendido, ";
        //     }
        //     if (filterForm["prosody"]["tone_circumflex"]) {
        //         toneFilter += "circunflejo, ";
        //     }
        //     if (!this.$mout.lang.isEmpty(toneFilter)) {
        //         //                    console.log(toneFilter);
        //         toneFilter = this.$mout.string.rtrim(toneFilter, ", ");

        //         search = "\t+ Entonación de tipo " + subactTypeFilter + ".";

        //         this.addNewLine(search, docObject);
        //     }


        //     var subactTypeFilter = "";
        //     if (filterForm["units"]["subactsSAX"]) {
        //         subactTypeFilter += "SAX, ";
        //     }
        //     if (filterForm["units"]["subactsSAT_M"]) {
        //         subactTypeFilter += "SAT_M, ";
        //     }
        //     if (filterForm["units"]["subactsSAT_I"]) {
        //         subactTypeFilter += "SAT_I, ";
        //     }
        //     if (filterForm["units"]["subactsSAM_T"]) {
        //         subactTypeFilter += "SAM_T, ";
        //     }
        //     if (filterForm["units"]["subactsSAI"]) {
        //         subactTypeFilter += "SAI, ";
        //     }
        //     if (filterForm["units"]["subactsSAI_T"]) {
        //         subactTypeFilter += "SAI_T, ";
        //     }
        //     if (filterForm["units"]["subactsSSX"]) {
        //         subactTypeFilter += "SSX, ";
        //     }
        //     if (filterForm["units"]["subactsSAM_I"]) {
        //         subactTypeFilter += "SAM_I, ";
        //     }
        //     if (filterForm["units"]["subactsSAI_M"]) {
        //         subactTypeFilter += "SAI_M, ";
        //     }
        //     if (filterForm["units"]["subactsSS_SSA"]) {
        //         subactTypeFilter += "SS_SA, ";
        //     }
        //     if (filterForm["units"]["residue"]) {
        //         subactTypeFilter += "residue,";
        //     }
        //     if (filterForm["units"]["subactsSSS"]) {
        //         subactTypeFilter += "SSS, ";
        //     }
        //     if (filterForm["units"]["subactsSAI"]) {
        //         subactTypeFilter += "SAI, ";
        //     }
        //     if (filterForm["units"]["subactsSAM"]) {
        //         subactTypeFilter += "SAM, ";
        //     }
        //     if (filterForm["units"]["subactsSS_SA"]) {
        //         subactTypeFilter += "SS_SA, ";
        //     }
        //     if (filterForm["units"]["subactsSSTop"]) {
        //         subactTypeFilter += "SSS_Top, ";
        //     }
        //     if (filterForm["units"]["subactsSAT"]) {
        //         subactTypeFilter += "SAT, ";
        //     }
        //     if (filterForm["units"]["subactsSSD"]) {
        //         subactTypeFilter += "SSD, ";
        //     }
        //     if (!this.$mout.lang.isEmpty(subactTypeFilter)) {
        //         //                    console.log(subactTypeFilter)
        //         subactTypeFilter = this.$mout.string.rtrim(subactTypeFilter, ", ");

        //         search = "\t+ Buscando subactos de tipo: " + subactTypeFilter + ".";

        //         this.addNewLine(search, docObject);
        //     }

        //     var interventionTypeFilter = "";
        //     if (filterForm["units"]["initiative"]) {
        //         interventionTypeFilter += "independiente, ";
        //     }
        //     if (filterForm["units"]["reactive"]) {
        //         interventionTypeFilter += "reactiva, ";
        //     }
        //     if (filterForm["units"]["reactive_initiative"]) {
        //         interventionTypeFilter += "reactiva_iniciativa, ";
        //     }
        //     if (filterForm["units"]["independent"]) {
        //         interventionTypeFilter += "independiente,";
        //     }
        //     if (!this.$mout.lang.isEmpty(interventionTypeFilter)) {
        //         //                    console.log(interventionTypeFilter)
        //         interventionTypeFilter = this.$mout.string.rtrim(interventionTypeFilter, ", ");

        //         search = "\t+ Buscando intervenciones de tipo: " + subactTypeFilter + ".";

        //         this.addNewLine(search, docObject);
        //     }

        //     return search;
        // },
        addNewLine(text, docObject) {
            var lines;

            lines = [
                new TextRun({
                    text: text,
                })
            ];

            docObject["sections"][0]["children"].push(new Paragraph({
                alignment: AlignmentType.LEFT,
                spacing: {
                    after: 200
                },
                children: lines
            }));
        },
        async generateResultExcel(selectedRows, index) {
            var titleText, explanationText, summaryText, footerText, startCol, currentDate;
            var workbook;
            var worksheet;
            var rows;
            var border;
            var headerFillStyle, titleFontStyle, headerFontStyle, footerFontStyle;
            var headers;
            var startRow, col, rowNum, len;
            var self = this;

            if (selectedRows.lenght <= 0) {
                return;
            }

            currentDate = moment().locale("es").format("D MMMM YYYY");

            titleText = "Resultados de la búsqueda";
            explanationText = "Listado de " + self.defined_searchs[index]['result_items'].length +
                " resultados encontrados en " + self.defined_searchs[index]['result_conversation_count'] +
                " conversaciones para el texto de búsqueda: '" + self.search_text + "'";
            footerText = "* Cita: Pons Bordería, Salvador (dir.) - Corpus Val.Es.Co 3.0. <http://www.valesco.es> [" + currentDate + "]";
            summaryText = this.getSearchSummary(self.defined_searchs[index]["filter_form"]);

            workbook = new ExcelJS.Workbook();

            workbook.created = new Date();
            workbook.modified = new Date();

            workbook.views = [
                {
                    x: 0, y: 0, width: 10000, height: 20000,
                    firstSheet: 0, activeTab: 1, visibility: 'visible'
                }
            ];

            worksheet = workbook.addWorksheet('Resultados', {
                headerFooter: { firstHeader: "Resultados de la búsqueda", firstFooter: "Corpus Valesco 3.0" }
            });

            border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            headerFillStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } };
            titleFontStyle = {
                name: 'Arial',
                color: { argb: 'da8d2b' },
                bold: true,
                family: 2,
                size: 14
            };
            headerFontStyle = {
                name: 'Arial',
                color: { argb: 'da8d2b' },
                family: 2,
                size: 11
            };
            footerFontStyle = {
                name: 'Arial',
                family: 2,
                size: 11,
                italic: true
            };

            worksheet.mergeCells('B2:E2');
            worksheet.getRow('2').height = 30;
            worksheet.getCell('C2').value = titleText;
            worksheet.getCell('C2').font = titleFontStyle;
            worksheet.getCell('C2').alignment = { vertical: 'middle', horizontal: 'center' };

            worksheet.mergeCells('B3:E3');
            //                worksheet.getRow('3').height = 30;
            worksheet.getCell('C3').value = explanationText;
            //                worksheet.getCell('C3').font = titleFontStyle;
            worksheet.getCell('C3').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

            var summaryLineHeight;

            summaryLineHeight = 30;
            if ((summaryText.split("\n").length - 1) >= 0) {
                summaryLineHeight += (summaryText.split("\n").length - 1) * 17;
            }

            // console.log("summary", summaryText)
            // console.log("count", summaryText.split("\n").length-1)
            // console.log("height", summaryLineHeight)

            worksheet.mergeCells('B4:E4');
            worksheet.getCell('C4').value = summaryText;
            worksheet.getRow('4').height = summaryLineHeight;
            worksheet.getCell('C4').alignment = { vertical: 'top', horizontal: 'left', wrapText: true };

            headers = [
                { header: 'CONVERSACIÓN', key: 'conversation', width: 20, alignment: { vertical: 'middle', horizontal: 'center' } },
                { header: 'TIPO', key: 'intervention_type', width: 20, alignment: { vertical: 'middle', horizontal: 'center' } },
                { header: 'HABLANTE', key: 'speaker', width: 15, alignment: { vertical: 'middle', horizontal: 'center' } },
                { header: 'CONTENIDO', key: 'contents', width: 100, alignment: { vertical: 'middle', horizontal: 'left', wrapText: true } }
            ];

            startCol = "B";
            startRow = 6;

            col = startCol.toUpperCase();
            rowNum = startRow;

            len = headers.length;
            for (let i = 0; i < len; i++) {
                let nameCol;
                let cell;

                cell = worksheet.getCell(col + rowNum);
                cell.value = headers[i].header;
                nameCol = worksheet.getColumn(col);

                if (headers[i].format) {
                    nameCol.numFmt = headers[i].format;
                }

                nameCol.width = headers[i].width;
                cell.height = 20;
                cell.border = border;
                cell.font = headerFontStyle;
                cell.fill = headerFillStyle;
                cell.alignment = { vertical: 'middle', horizontal: 'center' };

                col = self.nextColumn(col);
            }

            // Add an array of rows
            rows = [];

            this.$mout.array.forEach(selectedRows, function (item, index, arr) {
                var turnNumber, interventionNumber, interventionKey;
                //                    var startIndex;
                var excelLine;
                //                    console.log(index, ' : ', item);


                //                    startIndex = item['speaker'].indexOf("speaker_key") + 13;
                //                    interventionKey = item['speaker'].substring(startIndex, item['speaker'].indexOf("span", startIndex) - 2);
                interventionKey = item["intervention_key"];
                turnNumber = item['turn_number'];
                interventionNumber = item['intervention_number'];


                excelLine = {
                    'conversation': item["conversation"]["name"],
                    'intervention_type': item["intervention_type"],
                    'speaker': turnNumber + interventionKey + interventionNumber,
                    'contents': item["plain_contents"]
                };
                rows.push(excelLine);
            });

            // Set header row height
            const row = worksheet.lastRow;
            row.height = 35;

            for (let r of rows) {
                let cell;
                let i;

                rowNum++;
                col = startCol;
                //                    console.log("Row", r);
                //                    worksheet.getRow(rowNum).height = 30;

                i = 0;
                for (let key in r) {
                    cell = worksheet.getCell(col + rowNum);

                    cell.value = r[key];
                    cell.border = border;
                    cell.alignment = headers[i].alignment;

                    col = self.nextColumn(col);
                    i++;
                    //                        console.log("Column", r[key]);
                }
            }

            rowNum += 2;
            col = "E";

            //                console.log(startCol + rowNum + ":" + col + rowNum)

            worksheet.mergeCells(startCol + rowNum + ":" + col + rowNum);
            worksheet.getRow(rowNum).height = 20;
            worksheet.getCell(startCol + ":" + rowNum).value = footerText;
            worksheet.getCell(startCol + ":" + rowNum).font = footerFontStyle;
            worksheet.getCell(startCol + ":" + rowNum).alignment = { vertical: 'middle', horizontal: 'left' };

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                self.downloadBlob(blob, 'ejemplo.xlsx');
            });
        },
        getSearchSummary(filterForm) {
            var search;

            search = "Filtros de búsqueda:";

            if (filterForm["conv_year_init"] && filterForm["conv_year_end"]) {
                search += "\n\t+ Conversaciones grabadas entre el " + filterForm["conv_year_init"];
                search += " y el " + filterForm["conv_year_end"] + ".";
            }

            // Format options filter
            if (filterForm["options"]["token_mode"]) {
                search += "\n\t+ Buscando por " + (filterForm["options"]["token_mode"] === "shape" ? "forma" : "lema");
                search += filterForm["options"]["capital_mode"] ? " teniendo en cuenta mayúsculas" : "";
                search += (filterForm["options"]["capital_mode"] && filterForm["options"]["accents"]) ? " y" : "";
                search += (filterForm["options"]["accents"]) ? " teniendo en cuenta acentos" : "";
            }

            if (filterForm["options"]["filter_position"]) {
                search += "\n\t+ Aparece ";
                switch (filterForm["options"]["filter_position"]) {
                    case "preceded":
                        search += "precedido";
                        break;
                    case "followed":
                        search += "seguido";
                        break;
                    case "both":
                        search += "precedido y seguido";
                        break;
                }

                search += " por "

                if (filterForm["options"]["filter_position_char"]) {
                    switch (filterForm["options"]["filter_position_char"]) {
                        case "exclamation_mark":
                            search += "¡!";
                            break;
                        case "question_marks":
                            search += "¿?";
                            break;
                        case "slash":
                            search += "/";
                            break;
                    }
                }
            }


            if (filterForm["options"]["word_type"]) {
                search += "\n\t+ Clase de palabra: " + filterForm["options"]["word_type"] + ".";
            }

            if (filterForm["options"]["type"]) {
                search += "\n\t+ De tipo: " + filterForm["options"]["type"] + ".";
            }


            if (filterForm["options"]["subtype"]) {
                search += "\n\t+ De subtipo: " + filterForm["options"]["subtype"] + ".";
            }

            if (filterForm["options"]["person"]) {
                search += "\n\t+ Persona: " + filterForm["options"]["person"] + ".";
            }

            if (filterForm["options"]["genre"]) {
                search += "\n\t+ Con género: " + filterForm["options"]["genre"] + ".";
            }

            if (filterForm["options"]["number"]) {
                search += "\n\t+ Con número: " + filterForm["options"]["number"] + ".";
            }

            if (filterForm["options"]["mood"]) {
                search += "\n\t+ De modo: " + filterForm["options"]["mood"] + ".";
            }


            // Format metadata filter
            if (filterForm["metadata"]["conversation"]) {
                search += "\n\t+ Buscando en la conversación " + filterForm["options"]["conversation"] + ".";
            }


            if (filterForm["metadata"]["conversation_type"]) {
                search += "\n\t+ Buscando en conversaciones de tipo ";
                switch (filterForm["metadata"]["conversation_type"]) {
                    case "cod_prototypical":
                        search += "prototípica.";
                        break;
                    case "cod_periferical":
                        search += "periférica.";
                        break;
                }
            }


            if (filterForm["metadata"]["speaker_genre"]) {
                search += "\n\t+ Buscando en conversaciones en las que aperezca un hablante ";
                search += filterForm["metadata"]["speaker_genre"] === "H" ? "hombre." : "mujer.";
            }


            if (filterForm["metadata"]["speaker_educational_level"]) {
                search += "\n\t+ Buscando en conversaciones con hablantes de nivel educativo ";
                switch (filterForm["metadata"]["speaker_educational_level"]["cod"]) {
                    case "cod_high_level":
                        search += "alto.";
                        break;
                    case "cod_medium_level":
                        search += "medio.";
                        break;
                    case "cod_primary_level":
                        search += "bajo.";
                        break;
                    case "cod_unknown":
                        search += "desconocido.";
                        break;
                }
            }


            if (filterForm["metadata"]["speaker_age_range"]) {
                search += "\n\t+ Buscando en conversaciones con hablantes ";
                switch (filterForm["metadata"]["speaker_age_range"]["cod"]) {
                    case "cod_age_range_1":
                        search += "de edades comprendidas entre los 18 y 34 años.";
                        break;
                    case "cod_age_range_2":
                        search += "de edades comprendidas entre los entre 35 y 54 años.";
                        break;
                    case "cod_age_range_3":
                        search += "de más de 55 años.";
                        break;
                }
            }


            if (filterForm["metadata"]["speaker_age_range_2"]) {
                search += "\n\t+ Buscando en conversaciones con hablantes ";
                switch (filterForm["metadata"]["speaker_age_range_2"]["cod"]) {
                    case "cod_age_range_2_1":
                        search += "de edades comprendidas entre los 18 y 34 años.";
                        break;
                    case "cod_age_range_2_2":
                        search += "de edades comprendidas entre los entre 35 y 64 años.";
                        break;
                    case "cod_age_range_2_3":
                        search += "de más de 64 años.";
                        break;
                }
            }

            // Format units filter
            var toneFilter = "";
            if (filterForm["prosody"]["tone_ascending"]) {
                toneFilter += "ascendente, ";
            }
            if (filterForm["prosody"]["tone_descending"]) {
                toneFilter += "descendente, ";
            }
            if (filterForm["prosody"]["tone_suspended"]) {
                toneFilter += "suspendido, ";
            }
            if (filterForm["prosody"]["tone_circumflex"]) {
                toneFilter += "circunflejo, ";
            }
            if (!this.$mout.lang.isEmpty(toneFilter)) {
                //                    console.log(toneFilter);
                toneFilter = this.$mout.string.rtrim(toneFilter, ", ");

                search += "\n\t+ Entonación de tipo " + subactTypeFilter + ".";
            }


            var subactTypeFilter = "";
            if (filterForm["units"]["subactsSAX"]) {
                subactTypeFilter += "SAX, ";
            }
            if (filterForm["units"]["subactsSAT_M"]) {
                subactTypeFilter += "SAT_M, ";
            }
            if (filterForm["units"]["subactsSAT_I"]) {
                subactTypeFilter += "SAT_I, ";
            }
            if (filterForm["units"]["subactsSAM_T"]) {
                subactTypeFilter += "SAM_T, ";
            }
            if (filterForm["units"]["subactsSAI"]) {
                subactTypeFilter += "SAI, ";
            }
            if (filterForm["units"]["subactsSAI_T"]) {
                subactTypeFilter += "SAI_T, ";
            }
            if (filterForm["units"]["subactsSSX"]) {
                subactTypeFilter += "SSX, ";
            }
            if (filterForm["units"]["subactsSAM_I"]) {
                subactTypeFilter += "SAM_I, ";
            }
            if (filterForm["units"]["subactsSAI_M"]) {
                subactTypeFilter += "SAI_M, ";
            }
            if (filterForm["units"]["subactsSS_SSA"]) {
                subactTypeFilter += "SS_SA, ";
            }
            if (filterForm["units"]["residue"]) {
                subactTypeFilter += "residue,";
            }
            if (filterForm["units"]["subactsSSS"]) {
                subactTypeFilter += "SSS, ";
            }
            if (filterForm["units"]["subactsSAI"]) {
                subactTypeFilter += "SAI, ";
            }
            if (filterForm["units"]["subactsSAM"]) {
                subactTypeFilter += "SAM, ";
            }
            if (filterForm["units"]["subactsSS_SA"]) {
                subactTypeFilter += "SS_SA, ";
            }
            if (filterForm["units"]["subactsSSTop"]) {
                subactTypeFilter += "SSS_Top, ";
            }
            if (filterForm["units"]["subactsSAT"]) {
                subactTypeFilter += "SAT, ";
            }
            if (filterForm["units"]["subactsSSD"]) {
                subactTypeFilter += "SSD, ";
            }
            if (!this.$mout.lang.isEmpty(subactTypeFilter)) {
                //                    console.log(subactTypeFilter)
                subactTypeFilter = this.$mout.string.rtrim(subactTypeFilter, ", ");

                search += "\n\t+ Buscando subactos de tipo: " + subactTypeFilter + ".";
            }

            var interventionTypeFilter = "";
            if (filterForm["units"]["initiative"]) {
                interventionTypeFilter += "independiente, ";
            }
            if (filterForm["units"]["reactive"]) {
                interventionTypeFilter += "reactiva, ";
            }
            if (filterForm["units"]["reactive_initiative"]) {
                interventionTypeFilter += "reactiva_iniciativa, ";
            }
            if (filterForm["units"]["independent"]) {
                interventionTypeFilter += "independiente,";
            }
            if (!this.$mout.lang.isEmpty(interventionTypeFilter)) {
                interventionTypeFilter = this.$mout.string.rtrim(interventionTypeFilter, ", ");

                search += "\n\t+ Buscando intervenciones de tipo: " + subactTypeFilter + ".";
            }

            return search;
        },
        async loadConversation(conversationName) {
            var queryString;
            var conversation;

            this.conversation_loading = true;

            queryString = "fields=name";

            // Get all sections for page
            conversation = await api.getAllItems("conversations", conversationName, queryString);

            return conversation[0];
        },
        nextColumn(current) {
            let alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            return alphabet[alphabet.indexOf(current) + 1];
        },
        async downloadBlob(blob, filename) {
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename;

            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link);
        }
    }
};
</script>
<style>
.selected {
    background-color: #fde19a !important;
}

.directSpeech tr {
    background-color: #8fbc8f5c !important;
}

.directSpeech .interv-contents {
    font-style: italic;
}

.observation {
    background-color: #d6e7ea !important;
}


.search-label-col {
    padding: 4px !important;
    padding-top: 8px !important;
}

.search-value-col {
    padding: 4px !important;
}

.v-input__slot {
    min-height: 30px !important;
}

.v-input--selection-controls {
    margin-top: 0px;
}

.headline {
    display: block;
    font-family: 'amaranth' !important;
    text-shadow: 1px 1px 1px #eaf1ea !important;
    line-height: 120% !important;
}

.filter-color {
    background-color: #FFF8E1 !important;
}

.filter-row-color {
    background-color: #FFE082 !important;
}

/*    .section-option i,span{
            color: #da8d2b;
        }*/

.section-title {
    padding: 0 0 20px 20px;
    line-height: 120%;
}

/*    .section-content{
            padding: 0 0 20px 20px;
            text-align: justify;
        }*/

.v-subheader {
    /*line-height: 50px !important;*/
    font-size: medium !important;
    /*font-weight: bold !important;*/
    color: black !important;
}

.v-tabs {
    height: 40px !important;
}

.v-expansion-panel-content__wrap {
    padding: 0 12px 12px;
}

.highlightText {
    color: darkorchid;
    /*background: yellow;*/
}

.morpho_key {
    margin-right: 5px;
    color: #f2b619;
}

.morpho_value {
    color: #fff;
}

.morpho a {
    color: steelblue !important;
}

.morpho_note {
    font-size: 80%;
    line-height: 1.2;
    align-content: center;
}

.context-axis {
    background: #ccccff;
    /*background: yellow;*/
}

.v-data-table td {
    padding: 0 10px;
}

.v-data-table td p {
    margin: 3px 0;
}

.v-data-table td i {
    font-weight: 300;
}

.speaker_turn_count {
    color: #e95e38;
    font-size: 95%;
}

.speaker_key {
    color: black;
}

.speaker_int_count {
    color: cornflowerblue;
    font-size: 95%;
}

.v-data-table__wrapper {
    overflow-x: visible;
    overflow-y: visible;
}

.v-data-table td {
    padding: 0 4px;
    margin: 0 !important;
}
</style>
